.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* background-color: #2a5ec7; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: -5vh;
  height: 105vh
}

.App-link {
  color: #61dafb;
}

.footer {
  position: fixed;
  width: 100%;
  height: 5vh;
  bottom: 0;
  padding: 2vh;
  list-style-type: none;
  display: inline;
}

.footer li {
  display: inline-block;
  margin-left: 7vw;
  margin-right: 7vw;
}