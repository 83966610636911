@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500&display=swap);
body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* background-color: #2a5ec7; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: -5vh;
  height: 105vh
}

.App-link {
  color: #61dafb;
}

.footer {
  position: fixed;
  width: 100%;
  height: 5vh;
  bottom: 0;
  padding: 2vh;
  list-style-type: none;
  display: inline;
}

.footer li {
  display: inline-block;
  margin-left: 7vw;
  margin-right: 7vw;
}
.applist {
    list-style-type: none;
    padding: 20px;
}

.appointment {
    background-color: #71cf6b;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.75em;
    text-align: left;
    margin-left: 0;
    margin-bottom: 20px;
    position: relative;
}

.appointment span {
    right: 10px;
    position: absolute;
    top: calc(50% - 0.75em);
}

.appointment p {
    padding: 0;
    margin: 0;
}

.upcoming {
    display: inline-block;
}

@media screen and (max-width: 500px) {
    .upcoming {
        width: 90vw;
    }
}

@media screen and (min-width: 500px) {
    .upcoming {
        width: 50%;
    }
}

.calendardiv {
    /* display: inline; */
    /* white-space: nowrap; */
    overflow-y: scroll;
    width: 90vw;
    height: 60vh;
    /* position: relative; */
    display: flex;
    border-collapse: collapse;
    table-layout: fixed;
}

.calendardiv tbody, .calendardiv tr {
    width: 100%;
}

.calendardiv tr td {
    height: 5vh;
    border-bottom: 1px dashed lightgray;
    overflow: hidden;
}

.timetd {
    width: 20vw;
}
.scheduletd {
    width: 80vw;
    position: relative;
}

.scheduletd div {
    position: absolute;
    width: 100%;
}

.backarrow {
    position: fixed;
    left: 20px;
    top: 20px;
}

.dateinput {
    background-color: #282c34 !important;
    border: none;
    color: white;
    font-size: 1em;
    font-family: 'Raleway' !important;
    display: inline;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.inputs li {
    display: inline;
    position: relative;
}

.inputs button {
    font-size: 1em;
    background-color: #71cf6b;
    border: none;
    color: white;
    margin-left: 20px;
    border-radius: 100px;
    padding: 10px;
    font-family: 'Raleway' !important;
}
 
.inputs {
    list-style-type: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.inputdiv {
    display: inline;
}

.errormessage {
    font-size: 0.5em;
    margin: 0;
    color: red;
}

.selected {
    background-color: rgb(126, 126, 126);
}

.blocked {
    background-color: rgb(161, 26, 26);
}

input {
    font-size: 1em;
    background: none;
    font-family: inherit !important;
    color: inherit;
    font-weight: bold;
    border: none;
    outline: inherit;
    border-bottom: 2px solid white;
}

h4 {
    margin: 0;
    margin-top: 20px;
    /* padding: 0 */
    font-weight: normal;
}

.settings {
    overflow-y: scroll;
    width: 90vw;
    height: 80vh;
}

.smalltext {
    font-size: 0.5em;
}

.pfp {
    width: 10vmax;
    height: 10vmax;
    border-radius: 5vmax;
    object-fit: cover;
}

.mentordiv {
    white-space: nowrap;
}

.mentordiv input {
    margin-left: 20px;
}

textarea {
    background: none;
    border: 1px solid white;
    color: white;
    font-size: 0.75em;
    width: 90%;
    font-family: 'Raleway' !important;
}

textarea:focus{
    outline: none;
}

.dateinput {
    background-color: #282c34 !important;
    border: none;
    color: white;
    font-size: 1em;
    font-family: 'Raleway' !important;
    display: inline;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

button {
    font-size: 0.75em;
    background-color: #71cf6b;
    border: none;
    color: white;
    margin-left: 20px;
    border-radius: 100px;
    padding: 10px;
    font-family: 'Raleway' !important;
}

.mentor {
    height: 60vh;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    display: inline-block;
    margin-right: 5vw;
    text-align: left;
    overflow-wrap: break-word;
    word-break: break-all;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
}

.mentor::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 500px) {
    .mentor {
        width: 80vw;
    }
    .mentorlist {
        padding-left: 10vw;
        width: 85%;
        padding-right: 5vw;
    }
}

@media screen and (min-width: 500px) {
    .mentor {
        width: 30vw;
    }
    .mentorlist {
        padding-left: 35vw;
        width: 35%;
        padding-right: 30vw;
    }
}

.mentor img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.mentor h3 {
    margin-top: -1.5em;
    margin-left: 2vw;
    font-size: 1.3em;
    text-shadow: 2px 2px 10px #000000;
}

.mentor p {
    color: black;
    font-size: 0.8em;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: -2vh;
    overflow-wrap: break-word;
    word-break: break-word;
    display: table;
    white-space: pre-wrap;
}

.mentor button {
    /* right: 2vw;
    align-items: right;
    margin-top: -2vh;
    margin-right: -10vw */
    position: absolute;
    right: 6%;
    top: 42.5%;
    width: 5vmax;
    height: 5vmax;
    border-radius: 2.5vmax;
    background-color: #71cf6b;
    border: none;
}

.mentorlist {
    list-style-type: none;
    display: inline;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: visible;
}

.mentorlist::-webkit-scrollbar {
    display: none;
}
.errorMessage {
    color: red;
    font-size: 0.7em;
}
