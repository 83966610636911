.mentor {
    height: 60vh;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    display: inline-block;
    margin-right: 5vw;
    text-align: left;
    overflow-wrap: break-word;
    word-break: break-all;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
}

.mentor::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 500px) {
    .mentor {
        width: 80vw;
    }
    .mentorlist {
        padding-left: 10vw;
        width: 85%;
        padding-right: 5vw;
    }
}

@media screen and (min-width: 500px) {
    .mentor {
        width: 30vw;
    }
    .mentorlist {
        padding-left: 35vw;
        width: 35%;
        padding-right: 30vw;
    }
}

.mentor img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.mentor h3 {
    margin-top: -1.5em;
    margin-left: 2vw;
    font-size: 1.3em;
    text-shadow: 2px 2px 10px #000000;
}

.mentor p {
    color: black;
    font-size: 0.8em;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: -2vh;
    overflow-wrap: break-word;
    word-break: break-word;
    display: table;
    white-space: pre-wrap;
}

.mentor button {
    /* right: 2vw;
    align-items: right;
    margin-top: -2vh;
    margin-right: -10vw */
    position: absolute;
    right: 6%;
    top: 42.5%;
    width: 5vmax;
    height: 5vmax;
    border-radius: 2.5vmax;
    background-color: #71cf6b;
    border: none;
}

.mentorlist {
    list-style-type: none;
    display: inline;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: visible;
}

.mentorlist::-webkit-scrollbar {
    display: none;
}